<template>
  <div
    class="badge"
    :class="{
      [`badge--${color}`]: color,
      [`badge--${size}`]: size,
      [`badge--border-${border}`]: border,
      [`badge--corner-${bevelCorner}`]: bevelCorner,
    }"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import './Badge.css'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

type BadgeColor = 'brown' | 'grey-dark' | 'grey-light' | 'green' | 'green-light' | 'blue' | 'orange' | undefined
type BadgeSize = 'lg' | 'sm' | 'xs' | undefined
type BadgeBevelCorner = 'left-top' | 'right-bottom' | undefined
type BadgeBorder = 'xs' | undefined

export default defineComponent({
  name: 'GlobalABadge',
  props: {
    color: {
      type: String as PropType<BadgeColor>,
      default: undefined
    },
    size: {
      type: String as PropType<BadgeSize>,
      default: undefined
    },
    border: {
      type: String as PropType<BadgeBorder>,
      default: undefined
    },
    bevelCorner: {
      type: String as PropType<BadgeBevelCorner>,
      default: undefined
    }
  }
})
</script>
